// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import { version } from './version';

export const environment = {
  production: false,
  appVersionAddon: '-staging',
  webappName: 'Milieu Klachten Applicatie (staging)',
  api_endpoint: 'https://api.mka-staging.nl/',
  latitude: 51.55,
  longitude: 5.083333,
  sentry: {
    dsn: 'https://5a4b5de235c4794ce0a7db7f46c0000b@o4507735602298880.ingest.de.sentry.io/4507748307632208',
    version: version + '-staging',
  }
};
